import React, { FC, createContext, useEffect, useState } from "react";
import {
  useChangeUserSettings,
  useFreeWinLogin,
  useGetUser,
} from "./_requests";
import { ID, WithChildren } from "../../../../_metronic/helpers";
import { SelectAccount, TotpStatus } from "../../../../api/core-contract";

interface Context {
  TOTPStatus?: TotpStatus | null;
  updateTOTPStatus: (TOTPStatus: any) => void;
}

export const UserContext = createContext<Context | any | null>(null);

export const UserContextProvider: FC<WithChildren> = ({ children }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [selectAccount, setSelectAccount] = useState<SelectAccount | null>(
    null
  );
  const [modalMode, setModalMode] = useState<string | undefined>(undefined);

  const {
    data: userData,
    isFetching: isUserDataFetching,
    // refetch: userDataRefetch,
  } = useGetUser();

  const { mutate: getFreeWinLogin, isLoading: isFreeWinLoginLoading } =
    useFreeWinLogin();

  const { mutate: changeUserSettings, isLoading: isChangeUserSettingsLoading } =
    useChangeUserSettings();

  const [TOTPStatus, setTOTPStatus] = useState<boolean | undefined | null>(
    false
  );
  const [locale, setLocale] = useState<string | undefined | null>(null);
  const [view, setView] = useState<string | undefined | null>(null);

  useEffect(() => {
    if (userData) {
      setTOTPStatus(userData?.settings?.totp ?? null);
      setLocale(userData?.settings?.locale ?? null);
      setView(userData?.settings?.view ?? null);

      setIsDataLoaded(true);
    }
  }, [userData]);

  const updateTOTPStatus = (newTOTPStatus: boolean) => {
    setTOTPStatus(newTOTPStatus);

    changeUserSettings({
      settings: { locale: locale, view: view, totp: newTOTPStatus },
    });
  };

  const updateLocaleView = (newLocale: string, newView: string) => {
    setLocale(newLocale);
    setView(newView);

    changeUserSettings({
      settings: { locale: newLocale, view: newView, totp: TOTPStatus },
    });
  };

  return (
    <UserContext.Provider
      value={{
        locale,
        view,
        updateLocaleView,
        TOTPStatus,
        updateTOTPStatus,
        isUserDataFetching,
        isChangeUserSettingsLoading,
        isFreeWinLoginLoading,
        isDataLoaded,
        selectAccount,
        setSelectAccount,
        modalMode,
        setModalMode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
